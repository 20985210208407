/**
 * 车牌类型
 */
export const VehiclePlateType = {
  REAL_PLATE: 'REAL_PLATE', // 真实车辆
  OPERATION_MOCK_PLATE: 'OPERATION_MOCK_PLATE', // 业务虚拟车辆
  TESTING_MOCK_PLATE: 'TESTING_MOCK_PLATE', // 产研测试车辆
};

/**
 * 车辆里程状态
 */
export const MileageCheckStatus = {
  DATA_ABNORMAL: 'DATA_ABNORMAL', // 更新正常
  UPDATE_ABNORMAL: 'UPDATE_ABNORMAL', // 更新异常
  MAINT_UNCOVERED: 'MAINT_UNCOVERED', // 数据异常
  UPDATE_NORMAL: 'UPDATE_NORMAL', // 已退保停更
  MAINT_UNCOVERED_INVALID: 'MAINT_UNCOVERED_INVALID', // 退保异常
};

/**
 * 车辆里程状态描述
 */
export const MileageCheckStatusDetail = {
  MILE_OVER_DEVIATION: 'MILE_OVER_DEVIATION', // 里程偏差过大
  GPS_NO_INSTALLED: 'GPS_NO_INSTALLED', // 未安装GPS
  GPS_DATA_LACK: 'GPS_DATA_LACK', // GPS数据未返回
  ORIGIN_MILE_LACK: 'ORIGIN_MILE_LACK', // 无初始里程
  MILE_SUSPECTED_PEAK: 'MILE_SUSPECTED_PEAK', // 仪表疑似峰值
};

/**
 * 待办工时来源
 */
export const MaintWorkinghourSources = {
  MAINT_ORDER_DISPATCHED: 'MAINT_ORDER_DISPATCHED', //派单
  MAINT_ORDER_RECORDED: 'MAINT_ORDER_RECORDED', // 录单
  MAINT_ORDER_APPROVED: 'MAINT_ORDER_APPROVED', // 核准报价
  MAINT_PLAN: 'MAINT_PLAN', // 维保计划
};

/**
 * 直供件库存明细变化类型
 */
export const InventoryChangeReason = {
  INCREASE_INVENTORY: 'INCREASE_INVENTORY', // 入库
  DEDUCT_RETURN: 'DEDUCT_RETURN', //抵减退货
};

/**
 * 国家代码
 */
export const CountryCode = {
  CHN: 'CHN', // 中国
  PHL: 'PHL', // 菲律宾
  MYS: 'MYS', // 马来西亚
};

/**
 * 货币符号
 */
export const CurrencySymbol = {
  RMB: '¥', // 人民币
  MYR: 'RM', // 林吉特
};

/**
 * 国际化请求头枚举
 */
export const RequestHeaderLocale = {
  EN_US: 'en_US',
  ZH_CN: 'zh_CN',
};
